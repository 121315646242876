import request from "@/utils/http/requset";

export function router(data) {
  return request({
    url: "/manage/merchant/pay/router",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
export function withdraw(data) {
  return request({
    url: "/manage/merchant/withdraw/router",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}

export function save(data) {
  return request({
    url: "/manage/merchant/pay/router/save",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}

export function dropChannel(data) {
  return request({
    url: "/manage/merchant/pay/in/channel/drop",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}

export function withdrawSave(data) {
  return request({
    url: "/manage/merchant/withdraw/router/save",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}


// 新增接口
export function configParamsApi(data) {
  return request({
    url: "/manage/configParams/search",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
export function configUpdateApi(data) {
  return request({
    url: "/manage/configParams/update",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}